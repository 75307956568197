<template>
	<div>
		<div class="page-title">
			<a>
				<router-link :to="{name:'prize.record/index'}">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">批量发货</span>
		</div>
		<div class="bg-white p-3 m-2">
			<el-form ref="form" :rules="rules" :model="form" label-width="100px" style="padding-right: 50%;">
				<el-form-item label="模板下载">
					<el-button type="primary" @click="hanledDow()" size="mini" icon="el-icon-bottom">模板下载
					</el-button>
				</el-form-item>
				<el-form-item label="上传数据" prop="file">
					<label v-if="form.file != ''">{{form.file ? form.file.name : ''}}</label>
					<el-upload action="" :multiple="false" :http-request="handleFile" :on-change="excelChange"
						accept=".csv" :show-file-list="false">
						<span style="color: #409EFF;">选择本地文件</span>
					</el-upload>
				</el-form-item>
				<el-form-item label="物流公司" prop="express_id">
					<el-select v-model="form.express_id" placeholder="物流公司">
						<el-option :label="item.express_name" v-for="item in expressList" :value="item.express_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit('form')">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				fileList: [],
				expressList: [],
				form: {
					express_id: '',
					file: '',
				},
				rules: {
					express_id: [{
						required: true,
						message: '请选择物流公司',
						trigger: 'change'
					}, ],
					file: [{
						required: true,
						message: '请上传文件',
						trigger: 'change'
					}, ],
				},
			}
		},
		created() {
			this.getExpressList()
		},
		methods: {
			handleFile() {},
			excelChange(file, fileList) {
				this.form.file = file.raw;
			},
			hanledDow() {
				window.location.href = this.$conf.base_url + 'batchdelivery.csv'
			},
			getExpressList() {
				this.axios({
					token: true,
					params: {
						s: 'store/data.express/index',
					},
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						this.expressList = res.data.data
					}
				}).catch(err => {})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let formData = new FormData();
						formData.append('file', this.form.file);
						formData.append('express_id', this.form.express_id);
						this.axios({
							token: true,
							params: {
								s: 'store/prize.record/batchdelivery',
							},
							data: formData,
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'prize.record/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
						}).catch(err => {
						})
					}
				})
			},
		},
	}
</script>

<style>
</style>
